import axios from 'axios';

export function getIllustration(illustration, format = 'medium') {
    if (illustration) {
        if (illustration.formats && illustration.formats[format]) {
            return illustration.formats[format].url;
        }
        return illustration.url;
    }
    return 'https://cdn.vuetifyjs.com/images/cards/mountain.jpg';
}

export function getAuthorDisplayName(author) {
    const capitalize = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    if (author.nickname) {
        return capitalize(author.nickname);
    }
    return [capitalize(author.firstname), capitalize(author.lastname)].join(
        ' '
    );
}

export function getIllustrationUrlFromEpisode(episode) {
    let url = this.defaultImage;
    if (episode.illustration) {
        url = getIllustration(episode.illustration);
        return url;
    }
    if (episode.opus.illustration) {
        url = getIllustration(episode.opus.illustration);
        return url;
    }
    return url;
}

export async function getAuthorNameById(id) {
    if (!id) {
        return null;
    }
    const resp = await axios.get(
        process.env.VUE_APP_API_URL + `/authors/${id}`
    );
    const author = resp.data;
    if (author.nickname !== '') {
        return author.nickname;
    }
    return [author.user.firstname, author.user.lastname].join(' ');
}

export function sortByPublishedDate(descendent = false) {
    const sortfunction = (a, b) => {
        if (a.published_at === b.published_at) {
            return 0;
        }
        const isInOrder = descendent
            ? a.published_at > b.published_at
            : a.published_at < b.published_at;
        if (isInOrder) {
            return 1;
        }

        return -1;
    };
    return sortfunction;
}

export function retrieveText(content) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, 'text/html');
    const paragraphs = doc.querySelectorAll('p');
    const text = [];
    paragraphs.forEach((paragraph) => {
        text.push(paragraph.textContent);
    });
    return text.join(' ');
}
