<template>
    <div id="legal">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="content"></div>
    </div>
</template>
<script>
import axios from 'axios';
import DOMPurify from 'dompurify';
export default {
    name: 'LegalWrapper',
    data() {
        return {
            content: '',
        };
    },
    async created() {
        const { data } = await axios.get(
            process.env.VUE_APP_API_URL +
                this.getPathFromSection(this.$route.params.section)
        );

        this.content = DOMPurify.sanitize(data.content);
    },
    methods: {
        getPathFromSection(section) {
            if (section === 'cgv') {
                return '/cgv';
            }
            if (section === 'rgpd') {
                return '/rgpd';
            }
            return '/legal-mentions';
        },
    },
};
</script>
<style>
#legal {
    margin: 0% 25%;
    text-align: justify;
}
</style>
