<template>
    <div>
        <!-- <v-toolbar class="d-flex toolbar" dense flat>
            <v-toolbar-title> Nos Livres </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-select
                    v-model="filters.categories"
                    class="category-select"
                    :items="categories"
                    chips
                    attach
                    clearable
                    label="Catégories"
                    multiple
                    persistent-hint
                    deletable-chips
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 1">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                        >
                            (+{{ filters.genres.length - 1 }} autre{{
                                filters.genres.length > 1 ? 's' : ''
                            }})
                        </span>
                    </template>
                </v-select>
            </v-toolbar-items>
            <v-toolbar-items>
                <v-select
                    v-model="filters.genres"
                    class="production-select"
                    :items="genres"
                    chips
                    attach
                    clearable
                    multiple
                    label="Genres"
                    persistent-hint
                    deletable-chips
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 1">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                        >
                            (+{{ filters.genres.length - 1 }} autre{{
                                filters.genres.length > 1 ? 's' : ''
                            }})
                        </span>
                    </template>
                </v-select>
            </v-toolbar-items>
            <v-toolbar-items class="d-flex">
                <v-text-field
                    v-model="filters.search"
                    class="d-flex search-field"
                    label="Rechercher"
                    placeholder="Titre ou auteur"
                    filled
                    rounded
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    single-line
                ></v-text-field>
            </v-toolbar-items>
        </v-toolbar> -->
        <!-- <v-container fluid class="container-padding">
            <v-row>
                <v-col v-for="(item, index) in fileterdOpuses" :key="index">
                    <blog-card
                        :episode-id="item.id"
                        :title="item.title"
                        :text="item.description"
                        :authors="item.authors"
                        :categories="item.categories"
                        :genres="item.genres"
                        :img="item.illustration"
                        :to="item.to"
                    />
                </v-col>
            </v-row>
        </v-container> -->
        <h1 id="annoucement">
            <i>Le Vent du Nord</i> écrit par Benjamin Calame est enfin
            disponible ! <br />
        </h1>
        <h3>Cliquez sur l'image pour plus d'information.</h3>
        <v-container v-if="illustration" fluid>
            <v-tooltip bottom right left top color="primary" close-delay="200">
                <template v-slot:activator="{ on, attrs }">
                    <a
                        id="illustration-a"
                        href="/opus/8"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-hover v-slot="{ hover }">
                            <v-card
                                :elevation="hover ? 2 : 24"
                                :class="{ 'on-hover': hover }"
                            >
                                <v-img
                                    :src="illustration.url"
                                    :width="illustration.width"
                                    :height="illustration.height"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0 fill-gray"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-hover>
                    </a>
                </template>
                <span><i>Le Vent du Nord</i>, par Benjamin Calame</span>
            </v-tooltip>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
// import BlogCard from '../components/BlogCard.vue';
import { getIllustration } from '../utils/index';

export default {
    name: 'Books',
    components: {},
    data() {
        return {
            opuses: [],
            filters: {
                search: '',
                genres: [],
                categories: [],
            },
            categories: [],
            genres: [],
            fileterdOpuses: [],
            illustration: null,
        };
    },
    watch: {
        filters: {
            deep: true,
            handler: function (val) {
                this.filterOpuses(val.search, val.genres, val.categories);
                this.$router
                    .replace({
                        query: {
                            ...this.$route.query,
                            search: encodeURI(val.search.trim()) || undefined,
                        },
                    })
                    .catch((error) => {
                        if (error.name !== 'NavigationDuplicated') {
                            throw error;
                        }
                    });
                this.$router
                    .replace({
                        query: {
                            ...this.$route.query,
                            genres:
                                val.genres && val.genres.length
                                    ? val.genres
                                          .map(encodeURIComponent)
                                          .join(', ')
                                    : undefined,
                        },
                    })
                    .catch((error) => {
                        if (error.name !== 'NavigationDuplicated') {
                            throw error;
                        }
                    });

                this.$router
                    .replace({
                        query: {
                            ...this.$route.query,
                            categories:
                                val.categories && val.categories.length
                                    ? val.categories
                                          .map(encodeURIComponent)
                                          .join(', ')
                                    : undefined,
                        },
                    })
                    .catch((error) => {
                        if (error.name !== 'NavigationDuplicated') {
                            throw error;
                        }
                    });
            },
        },
    },
    async created() {
        // let res = await axios.get(process.env.VUE_APP_API_URL + '/opuses');
        // this.transformOpuses(res.data);
        // res = await axios.get(process.env.VUE_APP_API_URL + '/genres');
        // this.genres = res.data.map((data) => data.name);
        // res = await axios.get(process.env.VUE_APP_API_URL + '/categories');
        // this.categories = res.data.map((data) => data.name);
        // const { search, genres, categories } = this.$route.query;
        // if (search) {
        //     this.filters.search = decodeURI(search);
        // }
        // if (genres) {
        //     this.filters.genres = genres.split(', ').map(decodeURI);
        // }
        // if (categories) {
        //     this.filters.categories = categories.split(', ').map(decodeURI);
        // }
        // this.filterOpuses(search, genres, categories);

        const res = await axios.get(process.env.VUE_APP_API_URL + '/opuses/8');
        const { data } = res;
        this.illustration = {
            url: data.illustration.formats.small.url,
            width: data.illustration.formats.small.width,
            height: data.illustration.formats.small.height,
        };
    },
    methods: {
        transformOpuses(opuses) {
            opuses.forEach((opus) => {
                this.opuses.push({
                    id: opus.id,
                    authors: opus.authors,
                    categories: opus.categories.map(
                        (category) => category.name
                    ),
                    genres: opus.genres.map((genre) => genre.name),
                    illustration: getIllustration(opus.illustration),
                    title: opus.title,
                    published_at: opus.published_at,
                    description: opus.description,
                    to: `/productions?search=${encodeURI(opus.title)}`,
                });
            });
        },
        filterOpuses(search, genres, categories) {
            this.fileterdOpuses = this.opuses;
            if (search) {
                const transformedSearch = search.toLowerCase().trim();
                this.fileterdOpuses = this.fileterdOpuses.filter(
                    (opus) =>
                        opus.title.toLowerCase().includes(transformedSearch) ||
                        opus.authors.some((author) =>
                            author.nickname
                                .toLowerCase()
                                .includes(transformedSearch)
                        )
                );
            }

            if (genres && genres.length) {
                this.fileterdOpuses = this.fileterdOpuses.filter((opus) => {
                    return genres.every((genre) => opus.genres.includes(genre));
                });
            }

            if (categories && categories.length) {
                this.fileterdOpuses = this.fileterdOpuses.filter((opus) => {
                    return opus.categories.every((category) =>
                        categories.includes(category)
                    );
                });
            }
        },
    },
};
</script>

<style lang="scss">
#annoucement {
    margin-top: 1em;
}

.container-padding {
    padding-left: 10%;
    padding-right: 5%;
}

.toolbar {
    background-color: inherit !important;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.v-toolbar__items {
    margin-left: 1em;
}

.search-field {
    margin: auto 0;
}

.production-select {
    max-width: 24rem;
}

.category-select {
    max-width: 24rem;
}

#illustration-a {
    display: inline-block;
}

#progress-circular {
    margin: auto;
}

.fill-gray {
    background-color: gray;
}

// .v-card.on-hover.theme--dark {
//     background-color: rgba(rgb(8, 8, 8), 0.8);
//     > .v-card__text {
//         color: #000;
//     }
// }
</style>
