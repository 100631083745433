<template>
    <div class="about">
        <h1>L'équipe de l'édition de l'embuscade</h1>
        <!--
		<v-container fluid>
			<v-row style="margin-left: 150px; margin-right: 150px">
				<v-col
					v-for="(item, index) in people.slice(0, 10)"
					:key="index"
				>
					<v-card width="300">
						<v-card-title
							v-text="item.first_name + ' ' + item.last_name"
						/>
						<v-card-subtitle v-text="item.role" />
						<v-avatar rounded size="200">
							<v-img :src="item.image" class="rounded-circle">
							</v-img>
						</v-avatar>
						<v-card-text v-text="item.description" />
					</v-card>
				</v-col>
			</v-row>
		</v-container> -->

        <v-container fluid>
            <v-row class="authors">
                <v-col
                    v-for="(item, index) in all_members"
                    :key="index"
                    class="author-column"
                >
                    <v-card v-if="item" class="author-cards">
                        <v-card-title v-text="item.nickname" />
                        <v-avatar rounded size="150">
                            <v-img
                                v-if="item.image"
                                :src="getUserPhoto(item)"
                                class="rounded-circle"
                            >
                                <template v-slot:placeholder>
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        type="image"
                                    ></v-skeleton-loader>
                                </template>
                            </v-img>
                        </v-avatar>
                        <v-card-text
                            class="author-description"
                            v-text="item.description"
                        />
                    </v-card>
                    <div v-else />
                </v-col>
            </v-row>
        </v-container>
        <!-- <h2>Les éditeurs</h2>

        <v-container fluid>
            <v-row class="editors">
                <v-col
                    v-for="(item, index) in editors"
                    :key="index"
                    class="author-column"
                >
                    <v-card v-if="item" width="450">
                        <v-card-title v-text="getAuthorPrintedName(item)" />
                        <v-card-subtitle v-text="item.role" />
                        <v-avatar rounded size="150">
                            <v-img
                                v-if="item.image"
                                :src="getUserPhoto(item)"
                                class="rounded-circle"
                            >
                            </v-img>
                            <v-img
                                v-else
                                src="https://robohash.org/molestiaeomnisminima.png?size=50x50&set=set1"
                                class="rounded-circle"
                            >
                            </v-img>
                        </v-avatar>
                        <v-card-text v-text="item.description" />
                    </v-card>
                    <div v-else />
                </v-col>
            </v-row>
        </v-container> -->
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'About',
    data() {
        // const data = require("../mock/MOCK_DATA");
        // const data = require("../mock/people");
        return {
            authors: [],
            editors: [],
            all_members: [],
        };
    },
    created: async function () {
        let resp = await axios.get(process.env.VUE_APP_API_URL + '/authors');
        this.authors = resp.data;
        // for (let i = 0; i < this.authors.length % 3; i++) {
        //     this.authors.push(null);
        // }
        this.authors.forEach((author) => {
            this.all_members.push(author);
        });

        resp = await axios.get(process.env.VUE_APP_API_URL + '/editors');

        this.editors = resp.data;
        // for (let i = 0; i < this.editors.length % 3; i++) {
        //     this.editors.push(null);
        // }
        this.editors.forEach((editor) => {
            this.all_members.push(editor);
        });

        for (let i = 0; i < this.all_members.length % 3; i++) {
            this.all_members.push(null);
        }
    },
    methods: {
        getUserPhoto(item) {
            const url = item.image.formats.small.url;
            return url;
        },
    },
};
</script>
<style scoped>
h1 {
    margin-top: 1em;
    margin-bottom: 1em;
}

.column {
    align-content: center;
    padding: 0% 20%;
}

.authors {
    margin-left: 10%;
    margin-right: 10%;
}

.author-cards {
    max-height: 35em;
    min-height: 25em;
    width: 450px;
}

.editors {
    margin-left: 10%;
    margin-right: 10%;
}

.author-description {
    text-align: justify;
    font-size: 18px;
    margin: auto;
}
</style>
